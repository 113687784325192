<template>
  <div>
    <div class="row issue-comments">
      <div class="col-xl-11">
        <h6 class="text-dark text-header">Comments</h6>
        <div class="row ticket-actions">
          <div class="col left-column" v-if="user.role !== 'Customer'">
            <div class="ticket-tab" v-on:click="setParamsList('client')" v-bind:class="[commentTab === 'client' ? 'active' : '']">
              Client<span>{{commentTotals.clientTotal}}</span>
            </div>
            <div class="ticket-tab" v-on:click="setParamsList('internal')" v-bind:class="[commentTab === 'internal' ? 'active' : '']">
              Internal<span>{{commentTotals.internalTotal}}</span>
            </div>
            <div class="ticket-tab" v-on:click="setParamsList('git')" v-bind:class="[commentTab === 'git' ? 'active' : '']" v-if="ticket.project && ticket.gitLabId">
              Gitlab<span>{{commentTotals.gitTotal}}</span>
            </div>
          </div>
        </div>
        <br>
        <div class="comment-form input-group mb-4">
          <div class="input-group-prepend">
            <img class="comment-avatar cursor-pointer" :src="$getAvatar(user.avatar)">
          </div>
          <div class="input-group-center">
            <Mentionable
              :keys="['@']"
              :items="options.users"
              offset="10"
            >
              <textarea
                rows="1"
                class="form-control"
                v-model="commentForm.content"
                v-if="formControl.simpleEditor"
              />
              <tiny-editor
                  v-else
                  v-model="commentForm.content"
                  :api-key="$tinyMCEAPIKey"
                  :init="tinyMCEconfig"
              />
              <!-- <editor
                v-model="commentForm.content"
                placeholder="Write a comment..."
              /> -->
            </Mentionable>
          </div>
          <div class="input-group-append" style="border: 0px" v-tooltip="`Toggle advance editor`" v-if="formControl.simpleEditor" v-on:click="toggleEditor()">
            <div class="btn text-light bg-secondary" style="border: 0px"><i class="fas fa-keyboard"></i></div>
          </div>
          <div class="input-group-append" v-tooltip="`Toggle basic editor`" v-else v-on:click="toggleEditor()">
            <div class="btn text-dark bg-light-grey" style="border: 0px"><i class="fas fa-keyboard"></i></div>
          </div>
          <input class="d-none" type="file" ref="commentFile" multiple v-on:change="addAttachment" />
          <div class="input-group-append" v-if="commentTab != 'git'" v-on:click="$refs.commentFile.click()">
            <div class="btn btn-secondary bg-primary-dark" style="border: 0px"><i class="fas fa-paperclip"></i></div>
          </div>
          <div class="input-group-append" v-on:click="createNewComment(false, false)">
            <div class="btn btn-primary" style="border: 0px"><i class="fas fa-paper-plane"></i></div>
          </div>
        </div>
        <div class="comment-form input-group mb-3" v-if="commentForm.attachments.length > 0">
          <div class="input-group-prepend">
            <img class="comment-avatar">
          </div>
          <div class="input-group-center attachment-list new-ticket">
            <div class="attachment-item mb-2" v-for="attachment in commentForm.attachments" v-bind:key="attachment.id" v-on:click="removeAttachment(attachment)" v-tooltip="attachmentName(attachment)">
              <i class="col-2 fas" v-bind:class="getFileIcon(attachment)"></i>
            </div>
          </div>
        </div>
        <div class="pl-5" ref="comment-wrapper">
          <div class="comment-wrapper comment-form input-group" v-for="comment in comments" v-bind:key="comment._id" :ref="`comment-${comment._id}`">

            <div class="input-group-prepend">
              <img class="comment-avatar cursor-pointer" :src="comment.fromGit ? comment.senderId.avatar : $getAvatar(comment.senderId.avatar)">
            </div>
            <div class="input-group-center" v-if="!comment.type || comment.type == 'discussions'">
              <div class="comment-bubble">
                <label class="text-header m-0">
                  {{comment.senderId.name}}
                  <small v-if="comment.email_reference" class="bg-primary-default border-rounded border-0 mx-2 py-0 px-2">Email Integrated</small>
                </label><br><hr class="my-2">
                <div
                  :id="'comment-content' + comment._id"
                  class="issue-description"
                  v-html="mentionReady(comment._id, imgViewerReady(comment.content))"
                >
                </div>
              </div>
              <div class="mt-2 attachment-list" v-if="comment.attachments && comment.attachments.length > 0">
                <div class="attachment-item p-3" style="width: 200px;" v-for="attachment in comment.attachments" v-bind:key="attachment._id" v-on:click="$openFile(attachment)" v-tooltip="attachment.originalname">
                  <i class="col-2 fas" v-bind:class="getFileIcon(attachment)"></i>
                  <div>
                    <span>{{attachment.originalname}}</span>
                    <label>{{attachment.size | convertSize}}</label>
                  </div>
                </div>
              </div>
              <br>
              <span v-if="commentTab !== 'git'">
                <small class="comment-reply" v-on:click="toggleReply(comment._id)">
                  Reply
                </small>
                &nbsp;&middot;&nbsp;
              </span>
              <small>{{comment.createdAt | dateEstimate}}</small><br class="mb-2">
              <div class="comment-replies" v-if="comment.responses.comments.length > 0 && !comment.responses.isActive" v-on:click="toggleReplies(comment._id)">
                <i class="fas fa-reply"></i>
                <small>{{comment.responses.comments.length}} {{comment.responses.comments.length > 1 ? `replies` : `reply`}}</small>
              </div>
              <div class="comment-replies" v-if="comment.responses.isActive">
                <div class="comment-wrapper comment-form input-group" v-for="response in comment.responses.comments" v-bind:key="response._id" :ref="`comment-${comment._id}`">
                  <div class="input-group-prepend">
                    <img class="comment-avatar cursor-pointer" :src="response.fromGit ? response.senderId.avatar : $getAvatar(response.senderId.avatar)">
                  </div>
                  <div class="input-group-center">
                    <div class="comment-bubble">
                      <label class="text-header m-0">
                        {{response.senderId.name}}
                        <small v-if="response.email_reference" class="bg-primary-default border-rounded border-0 mx-2 py-0 px-2">Email Integrated</small>
                      </label><br><hr class="my-2">
                      <div
                        class="issue-description"
                        v-html="imgViewerReady(response.content)"
                      >
                      </div>
                    </div>
                    <small class="comment-reply-date">{{response.createdAt | dateEstimate}}</small>
                  </div>
                </div>
              </div>
              <div class="comment-replies" v-if="comment.reply.isActive" :ref="`comment-reply-${comment._id}`">
                <div class="comment-form input-group my-2">
                  <div class="input-group-prepend">
                    <img class="comment-avatar cursor-pointer" :src="$getAvatar(user.avatar)">
                  </div>
                  <div class="input-group-center">
                    <Mentionable
                      :keys="['@']"
                      :items="options.users"
                      offset="10"
                    >
                      <textarea
                        rows="1"
                        class="form-control"
                        v-model="comment.reply.content"
                        v-if="formControl.simpleEditor"
                      />
                      <tiny-editor
                          v-else
                          v-model="commentForm.content"
                          :api-key="$tinyMCEAPIKey"
                          :init="tinyMCEconfig"
                      />
                      <!-- <editor
                        v-model="comment.reply.content"
                        placeholder="Write a reply..."
                      /> -->
                    </Mentionable>
                  </div>
                  <div class="input-group-append" style="border: 0px" v-tooltip="`Toggle advance editor`" v-if="formControl.simpleEditor" v-on:click="toggleEditor()">
                    <div class="btn text-light bg-secondary" style="border: 0px"><i class="fas fa-keyboard"></i></div>
                  </div>
                  <div class="input-group-append" v-tooltip="`Toggle basic editor`" v-else v-on:click="toggleEditor()">
                    <div class="btn text-dark bg-light-grey" style="border: 0px"><i class="fas fa-keyboard"></i></div>
                  </div>
                  <div class="input-group-append" v-on:click="createNewComment(comment, comment.reply)">
                    <div class="btn btn-primary"><i class="fas fa-paper-plane"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group-center" v-else>
              <div class="p-2 mt-1">
                <span class="text-header m-0">
                  {{comment.senderId.name}}
                </span>
                &nbsp;
                <span v-html="mentionReady(comment._id, imgViewerReady(comment.content))"/>
                &nbsp;|&nbsp;
                <span>{{comment.createdAt | dateEstimate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { Mentionable } from 'vue-mention'

  export default {
    props: {
      ticket: Object
    },
    components:{
      Mentionable
    },
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        commentForm: {
          ticketId: this.ticket._id,
          ticketNumber: this.ticket.ticketNumber,
          issue: this.ticket.issue,
          senderId: this.$userData.user,
          content: '',
          attachments: [],
          original_attachments: []
        },
        comments: [],
        commentTotals: {
          clientTotal: 0,
          internalTotal: 0,
          gitTotal: 0
        },
        commentTab: 'client',
        commentCounter: '',
        options: {
          users: []
        },
        formControl: {
          simpleEditor: true
        },
        users: [],
        tinyMCEconfig: {
          selector: 'textarea#template',
          height: 300,
          font_formats:
`Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Lato=lato; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Lato'); body { font-family: 'Lato'; font-size: 11pt; line-height: 1}`,
          plugins: [
            'template', 'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | template | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | lineheight | fontselect fontsizeselect | link image | insertfile | \
            bullist numlist outdent indent | removeformat',
          fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
          lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
          template_replace_values: {
            name: (this.$userData ? this.$userData.user : {}).name,
            email: (this.$userData ? this.$userData.user : {}).email,
            role: (this.$userData ? this.$userData.user : {}).role
          },
          template_preview_replace_values: {
            name: (this.$userData ? this.$userData.user : {}).name,
            email: (this.$userData ? this.$userData.user : {}).email,
            role: (this.$userData ? this.$userData.user : {}).role
          },
          templates: this.$quickReplies.filter(r =>
            this.$userData && this.$userData.user && this.$userData.user.roles == 'Customer' ? !r.isInternal : true
          )
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      attachmentName: function(attachment){
        return attachment.originalname || attachment.filename || attachment.name || ''
      },
      fileExt: function(filename){
        return filename.split('.').pop().toLowerCase()
      },
      getFileIcon: function(attachment){
        switch(this.fileExt(this.attachmentName(attachment))){
          case 'doc': case 'docx':
            return 'fa-file-word'
          case 'ppt': case 'pptx':
            return 'fa-file-powerpoint'
          case 'pdf':
            return 'fa-file-pdf'
          case 'xls': case 'xlsx':
            return 'fa-file-excel'
          case 'csv':
            return 'fa-file-csv'
          case 'zip': case 'rar': case '7z':
            return 'fa-file-archive'
          case 'jpeg': case 'jpg': case 'png': case 'gif': case 'tiff': case 'bmp': case 'img': case 'ico': case 'svg': case 'eps': case 'raw':
            return 'fa-file-image'
          case 'mp4': case 'mov': case 'avi': case 'flv': case 'wmv': case 'swf': case 'mkv': case 'mpeg':
            return 'fa-file-video'
          case 'txt': case 'html': case 'css': case 'js': case 'vue': case 'ts': case 'xml':
            return 'fa-file-code'
          default:
            return 'fa-file'
        }
      },
      addAttachment: function(event){
        var files = event.target.files;
        var invalidFiles = []
        for(var i = 0; i < files.length; i++){
          if (files[i].size <= 26214400){
            this.commentForm.attachments = this.commentForm.attachments.filter(s => s.name !== files[i].name)
            this.commentForm.attachments.push(files[i])
          }
          else
            invalidFiles.push(files[i].name)
        }
        invalidFiles.forEach(filename => {
          this.$alertify({
            group: 'notification',
            title: 'File attachment',
            type: 'warn',
            text: `${filename} exceeds 25mb`
          })
        });
      },
      removeAttachment: function(file){
        this.commentForm.attachments = this.commentForm.attachments.filter(s => s.name !== file.name)
        this.$alertify({
          group: 'notification',
          title: 'File attachment',
          type: 'info',
          text: `${file.name} was removed`
        })
      },
      setParamsList: function(val){
        this.commentTab = val;
        this.comments = []
        this.getComments();
      },
      toggleEditor: function(){
        this.formControl.simpleEditor = !this.formControl.simpleEditor
      },
      async getComments(){
        let resp = []
        const labelized = (content) => {
          return `<small class="px-2 py-1 border-rounded bg-primary-default text-light">${content}</small>`
        }
        const highlighted = (content, prefix = '') => {
          return `<span class="text-primary">${prefix}${content}</span>`
        }
        try {
          this.loader(true)
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/comments?ticketId=${this.ticket._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          resp = resp.concat(response.data.comments)
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
        if(this.ticket.project && this.ticket.gitLabId){
          try {
            this.loader(true)
            const discussions = await this.$http.get(
              `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}/discussions`,
              {
                headers: {
                  'Authorization': `Bearer ${this.$gitToken}`,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            resp = resp.concat(
              discussions.data.map(cmt => {
                return {
                  fromGit: true,
                  _id: cmt.id,
                  senderId: {
                    avatar: cmt.notes[0].author.avatar_url,
                    name: cmt.notes[0].author.name,
                    roles: 'Internal',
                    email: cmt.notes[0].author.username,
                    link: cmt.notes[0].author.web_url
                  },
                  type: 'discussions',
                  content: cmt.notes[0].system ? `${highlighted(cmt.notes[0].author.username, '@')} ${cmt.notes[0].body}` : cmt.notes[0].body,
                  createdAt: cmt.notes[0].created_at
                }
              })
            )
            this.loader(false);
          } catch (error) {
            console.log(error)
            this.loader(false);
          }
          try {
            this.loader(true)
            const resource_milestone_events = await this.$http.get(
              `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}/resource_milestone_events`,
              {
                headers: {
                  'Authorization': `Bearer ${this.$gitToken}`,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            resp = resp.concat(
              resource_milestone_events.data.map(cmt => {
                return {
                  fromGit: true,
                  _id: cmt.id,
                  senderId: {
                    avatar: cmt.user.avatar_url,
                    name: cmt.user.name,
                    roles: 'Internal',
                    email: cmt.user.username,
                    link: cmt.user.web_url
                  },
                  type: 'resource_milestone_events',
                  content: cmt.action === "add" ? `${highlighted(cmt.user.username, '@')} changed milestone to ${highlighted(cmt.milestone.title)}` : `${highlighted(cmt.user.username, '@')} removed milestone ${highlighted(cmt.milestone.title)}`,
                  createdAt: cmt.created_at
                }
              })
            )
            this.loader(false);
          } catch (error) {
            console.log(error)
            this.loader(false);
          }
          try {
            this.loader(true)
            const resource_label_events = await this.$http.get(
              `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}/resource_label_events`,
              {
                headers: {
                  'Authorization': `Bearer ${this.$gitToken}`,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            resp = resp.concat(
              resource_label_events.data.map(cmt => {
                return {
                  fromGit: true,
                  _id: cmt.id,
                  senderId: {
                    avatar: cmt.user.avatar_url,
                    name: cmt.user.name,
                    roles: 'Internal',
                    email: cmt.user.username,
                    link: cmt.user.web_url
                  },
                  type: 'resource_label_events',
                  content: cmt.action === "add" ? `${highlighted(cmt.user.username, '@')} added ${labelized(cmt.label.name)} label` : `${highlighted(cmt.user.username, '@')} removed ${labelized(cmt.label.name)} label`,
                  createdAt: cmt.created_at
                }
              })
            )
            this.loader(false);
          } catch (error) {
            console.log(error)
            this.loader(false);
          }
          try {
            this.loader(true)
            const resource_label_events = await this.$http.get(
              `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}/resource_state_events`,
              {
                headers: {
                  'Authorization': `Bearer ${this.$gitToken}`,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            resp = resp.concat(
              resource_label_events.data.map(cmt => {
                return {
                  fromGit: true,
                  _id: cmt.id,
                  senderId: {
                    avatar: cmt.user.avatar_url,
                    name: cmt.user.name,
                    roles: 'Internal',
                    email: cmt.user.username,
                    link: cmt.user.web_url
                  },
                  type: 'resource_state_events',
                  content: `${highlighted(cmt.user.username, '@')} ${labelized(cmt.state)} this issue`,
                  createdAt: cmt.created_at
                }
              })
            )
            this.loader(false);
          } catch (error) {
            console.log(error)
            this.loader(false);
          }
        }
        resp = resp.sort((a, b) => (new Date(b.createdAt)) - (new Date(a.createdAt))).reverse()
        this.commentTotals.clientTotal = resp.filter(r => !r.isInternal && !r.fromGit).length
        this.commentTotals.internalTotal = resp.filter(r => r.isInternal && !r.fromGit).length
        this.commentTotals.gitTotal = resp.filter(r => r.fromGit).length
        if(this.commentTab === 'client')
          this.comments = resp.filter(r => !r.isInternal && !r.fromGit && !r.parentId)
        else if(this.commentTab === 'internal')
          this.comments = resp.filter(r => r.isInternal && !r.fromGit && !r.parentId)
        else if(this.commentTab === 'git')
          this.comments = resp.filter(r => r.fromGit && !r.parentId)

        this.comments = this.comments.map(record => {
          return {
            ...record,
            content: (record.content || "").split('<div class="gmail_quote">')[0],
            responses: {
              comments: resp.filter(r => r.parentId === record._id) || [],
              isActive: false
            },
            reply: {
              content: '',
              attachments: [],
              isActive: false
            }
          }
        })
      },
      imgViewerReady: function(html){
        return html ? html.replace('<img', '<img class="ticket-description-img"') : ''
      },
      toggleReply: function(id){
        let comment = this.comments.find(r => r._id === id)
        this.comments.find(r => r._id === id).reply.isActive = !comment.reply.isActive
      },
      toggleReplies: function(id){
        let comment = this.comments.find(r => r._id === id)
        this.comments.find(r => r._id === id).reply.isActive = !comment.reply.isActive
        this.comments.find(r => r._id === id).responses.isActive = !comment.responses.isActive
        this.comments.find(r => r._id === id).reply.isActive = true
      },
      createNewComment: async function(parentComment, comment){
        this.loader(true)
        var files = parentComment ? comment.attachments : this.commentForm.attachments
        var attachments = []
        for (let i = 0; i < files.length; i++) {
          const attachment = new FormData()
          attachment.append('file', files[i])
          attachments.push(attachment)
        }
        let data = {
          ...this.commentForm,
          content: parentComment ? comment.content : this.commentForm.content,
          isInternal: parentComment ? parentComment.isInternal : !(this.user.roles === 'Customer' || this.commentTab === 'client'),
          attachments: []
        }
        let size = (new TextEncoder().encode(JSON.stringify(data)).length) / 1024 / 1024
        if(parentComment && comment)
          data.parentId = parentComment._id
        if(size > 40)
          this.$alertify({
            group: 'notification',
            title: 'Create comment',
            type: 'warn',
            text: 'API payload exceeds the limit. If there are images pasted on the ticket description, please consider adding them as links.'
          })
        else if(!data.content || this.$stripHtml(data.content).trim() === '')
          this.$alertify({
            group: 'notification',
            title: 'Create comment',
            type: 'warn',
            text: 'Comment cannot be empty'
          })
        else
          if(this.ticket.project && this.ticket.gitLabId && this.commentTab === 'git')
            this.submitNewGitComment(data)
          else{
            this.commentForm.original_attachments = []
            this.$uploadFiles(attachments).then(responses => {
              data.attachments = responses.map(r => r.data.filePath)
              data.attachments = data.attachments.concat(this.commentForm.original_attachments)
              this.submitNewComment(data)
            }).catch(errors => {
              console.log(errors)
              this.$alertify({
                group: 'notification',
                title: 'File attachment',
                type: 'warn',
                text: 'We encountered a problem while uploading the attachments. Please try again.'
              })
            })
          }
        this.loader(false)
      },
      submitNewGitComment: async function(data){
        this.loader(true)
        try {
          await this.$markDownImages(data.content, {id: this.ticket.project.gitLab.id, token: this.ticket.agent.gitlabPersonalToken})
          .then(async description => {
            const response = await this.$http.post(
              `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}/notes`,
              {
                body: description
              },
              {
                headers: {
                  'Authorization': `Bearer ${this.ticket.agent.gitlabPersonalToken}`,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            let comment = response.data;
            if(comment && comment.id){
              await this.getComments()
              this.$alertify({
                group: 'notification',
                title: `Create Comment`,
                type: 'success',
                text: `Your comment has been successfully added`
              })
              this.commentForm.content = ''
              this.commentForm.attachments = []
              if(comment.parentId){
                this.toggleReply(comment.parentId)
                this.toggleReplies(comment.parentId)
              }
              this.commentCounter = comment._id
              const el = this.$refs['comment-wrapper']
              if (el) {
                el.scrollIntoView({block: "end", behavior: 'smooth'});
              }
            }
            else{
              this.$alertify({
                group: 'notification',
                title: 'Create failed',
                type: 'warn',
                text: 'We encountered a problem while saving your comment. Please try again.'
              })
            }
          })
          this.loader(false)
        } catch (error) {
          this.$alertify({
            group: 'notification',
            title: 'Create failed',
            type: 'warn',
            text: 'We encountered a problem while saving your comment. Please try again.'
          })
          this.loader(false)
          console.log(error)
        }
      },
      submitNewComment: async function(data){
        this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/comments/new`,
              {comment: data},
              {
                headers: {
                  'Authorization': this.$userData.authToken,
                  'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          let comment = response.data.comment;
          if(comment && comment._id){
            await this.getComments()
            this.$alertify({
              group: 'notification',
              title: `Create Comment`,
              type: 'success',
              text: `Your comment has been successfully added`
            })
            this.commentForm.content = ''
            this.commentForm.attachments = []
            if(comment.parentId){
              this.toggleReply(comment.parentId)
              this.toggleReplies(comment.parentId)
            }
            this.commentCounter = comment._id
            const el = this.$refs['comment-wrapper']
            if (el) {
              el.scrollIntoView({block: "end", behavior: 'smooth'});
            }
          }
          else{
            this.$alertify({
              group: 'notification',
              title: 'Create failed',
              type: 'warn',
              text: 'We encountered a problem while saving your comment. Please try again.'
            })
          }
          this.loader(false)
        } catch (error) {
          this.$alertify({
            group: 'notification',
            title: 'Create failed',
            type: 'warn',
            text: 'We encountered a problem while saving your comment. Please try again.'
          })
          this.loader(false)
          console.log(error)
        }
      },
      async getUsers(){
        this.loader(true)
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent,Customer`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.users = response.data.users.filter(r => r._id != this.user._id)
          switch (this.user.role) {
            case 'Admin':
              this.options.users = this.users
              break;
            case 'Manager':
              this.options.users = this.users.filter(r => r.roles === 'Customer' || r.roles === 'Agent' || (r.roles === 'Manager' && r.isAgent))
              break;
            case 'Agent':
              this.options.users = this.users.filter(r => r.roles === 'Customer')
              break;
            case 'Customer':
              this.options.users = this.users.filter(r => r.roles === 'Agent' || (r.roles === 'Manager' && r.isAgent))
              break;
            default:
          }
          this.options.users = this.options.users.map(r => {
            return { value: r.email, label: `${r.name} <${r.email}>` }
          })
          this.loader(false)
        } catch (error) {
          console.log(error)
          this.loader(false)
        }
      },
      mentionReady: function(id, content){
        let mentions = this.getMentions(content)
        let mention = {}
        mentions.forEach(email => {
          mention = this.users.find(r => r.email === email.slice(1)) || { _id: '', name: email.slice(1), email: email.slice(1) }
          content = content.replace((new RegExp(email, 'g')),
            `<a target="_blank" href="/users/${mention._id}" title="${mention.email}" class="mention-link">${mention.name}</a>`
          )
        })
        return content
      },
      getTicketEmail: function(key){
        if(this.ticket && this.ticket.forms && this.ticket.forms.find(r => r.key === key))
          return this.ticket.forms.find(r => r.key === key).value
        else
          return null
      },
      getMentions: function(content) {
        let arr = content.match(/@([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
        arr = [...new Set(arr)]
        return arr
      }
    },
    filters: {
      convertSize: function(a, b = 2){
        if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]
      },
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      }
    },
    mounted: function() {
      if(this.ticket._id){
        this.getComments()
        this.getUsers()
      }
    }
  }
</script>
